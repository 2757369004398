iframe#webpack-dev-server-client-overlay{display:none!important}

.logo {
  margin: 2rem;
}

.utm-card {
  border-radius: 12px !important;
  text-align: center;
  padding: 0% !important;
  margin: 0% !important;

  p {
    font-weight: 500;
    font-size: 1rem;
    color: #fff !important;
  }

  h5 {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 0.3rem;
    color: #fff !important;
  }

  .revenue_text {
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 0.3rem;
    color: #fff !important;
  }
}

.stat_card {
  border-radius: 12px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 1rem;
  }

  h5 {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 0.3rem;
  }
}

.button1 {
  background-color: #3E8F7C !important;
  border-color: #3E8F7C !important;
  color: #fff !important;
}

.button2 {
  background-color: #fff !important;
  border-color: #3E8F7C !important;
  color: rgb(37, 37, 37) !important;
}

.subscription_details {
  .card-head {
    display: flex;
    justify-content: space-evenly;
  }
}

.spin-center {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.red {
  background: rgba(255, 0, 0, 0.144);
}

.green {
  background: rgba(21, 255, 0, 0.144);
}

.yellow {
  background: rgba(255, 187, 0, 0.144);
}

.teacher-card {
  margin: 0.7rem 0 !important;
  border-radius: 10px !important;
}

.teacher-details {
  img {
    width: 80%;
    height: 10rem;
    object-fit: cover;
    border-radius: 0;
  }

  .teacher-name {
    font-size: 1.5rem;
    color: rgb(230, 111, 0);
    font-weight: bolder;
    margin-bottom: 0.4rem;

    span {
      font-size: 0.8rem;
      color: rgb(134, 134, 134);
    }
  }

  .description {
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  .key {
    // font-family: 'Inter';
    // font-style: normal;
    font-weight: 500;
    font-size: 16px;
    // line-height: 24px;
    /* identical to box height, or 150% */

    // letter-spacing: -0.25px;

    /* Primary/800 */

    color: #0E433A;

  }

  .value {
    // font-family: 'Inter';
    // font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 22px;
    /* identical to box height, or 157% */

    // text-align: center;
    // letter-spacing: 1px;

    /* Primary/600 */

    color: #1D8674;

  }

  .active-stac {
    h5 {
      font-size: 1rem;
      color: rgb(230, 111, 0);
      font-weight: bold;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .access {
    margin: 1rem 0;
  }

  .edit-profile {
    .edit-name {
      display: flex;
      flex-direction: column;

      label {
        margin: 1rem 0.2rem 0 1rem;
        font-weight: 400;
      }

      input {
        outline: none;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0.5rem;
        margin: 0 1rem 1rem 1rem;
      }
    }
    .mt-6{
      margin-top: 6px;
    }
    .flax-div {
      display: flex;
      justify-content: space-between;
      margin: 1rem;
      align-items: center;
    }
  }
}

.ellipse {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.ant-upload.ant-upload-select-picture-card {
  width: 8rem !important;
  height: 8rem !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 8rem !important;
  height: 8rem !important;
}

.site-custom-tab-bar {
  z-index: 1;
  background: #fff;
}

//bookslot
.active{
  background-color: green;
}
.edit-name{
  display: flex;
  flex-direction: row;
  label{
    font-size: 12px;
  }
}

//timepicker
.hideSeconds .ant-picker-time-panel .ant-picker-content ul:nth-child(3){
  display: none;
}


// Resource-upload



.resource-container {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  text-align: center;
  font-weight: bold;
}

.resource-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resource-upload input {
  border: dashed 2px black;
  margin: auto;
  margin-top: 1.5rem;
  padding: 5rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}


.resource-container input, button{
  cursor: pointer;
}

.resource-container button {
  background-color: #1890ff;
  margin-top: 3rem;
  color: #fff;
  font-weight: bold;
}

.resource-heading{
  padding: 3rem;
  font-size: 1.5rem;
}

.resource-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.selected {
  background-color: #00aaff;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.search-input{
  border: 1px solid #858585;
  padding: 4px;
  border-radius: 2px 0 0 2px;
}


  .status-counts {
    background: #f2f2f2;
    padding: 8px 10px;
    margin: 8px 12px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    display: flex; /* Add this to enable flex layout */
    flex-direction: column; /* Stack child elements vertically */
    align-items: center;
    justify-content: center;
  
    p {
      line-height: 1; /* Adjust the line height as needed */
      margin: 0; /* Remove default margin for p element */
    }
  
    .title {
      font-weight: normal;
    }
  }


.topics{
  padding: 0 20px 0 20px;
  .mt-6{
    margin-top: 6px;
  }
  .ml-6{
    margin-left: 6px;
  }
}

.ml-2{
  margin-left: 6px;
}